<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

definePageMeta({
  key: route => route.path,
})

const state = useStore()

const { t } = useI18n()

const { ecommerce } = useAnalytics()

const container = ref<HTMLElement | null>(null)

function scrollToContainer() {
  if (container.value == null)
    return

  container.value.scrollIntoView({ behavior: 'smooth' })
}

const page = useRouteQuery('page', '1', {
  transform: (v) => {
    const page = parseInt(v)
    return isNaN(page) ? 1 : page
  },
})

const drawers = useDrawers()

useHead({
  title: computed(() => t('layouts.title', [t('pages.title.index')])),
})
</script>

<template>
  <div class="width: max-content">
    <div
      class="bg-cover bg-left bg-no-repeat py-60 bgi-[wd-fashion-m-slider-1.jpg] md:mx-auto md:mt-5 md:max-w-screen-xl md:bg-center md:py-75"
    >
      <div>
        <h1 class="text-center text-4xl">
          Witamy na Rivento
        </h1>
        <p class="text-center text-2xl">
          mamy coś dla każdego
        </p>
        <p class="mt5 text-center text-lg underline underline-offset-4">
          <a href="#productGrid">Spójrz na nasze
            ubrania</a>
        </p>
      </div>
    </div>
  </div>
  <div class="grid gap-1 px-5 pt-5 text-left md:grid-cols-3 md:mx-auto md:max-w-screen-xl md:gap-4 md:px-0 md:pt-5">
    <div
      class="min-h-120 flex items-end bg-cover bg-no-repeat bgi-[woman.jpg]"
    >
      <div class="max-w-100">
        <p class="mt5 p-5 text-lg font-bold underline underline-offset-4">
          <a href="/pl/collections/9010/dla-kobiet">Dla kobiet</a>
        </p>
      </div>
    </div>
    <div class="">
      <div class="min-h-60 flex items-end bg-cover bg-no-repeat bgi-[w-mf-category-2-min.png]">
        <div class="max-w-100">
          <p class="p-5 text-lg font-bold underline underline-offset-4">
            <a href="/pl/collections/9012/t-shirty-damskie">T-shirty damskie</a>
          </p>
        </div>
      </div>
      <div class="mt-4 min-h-60 flex items-end bg-cover bgi-[woman-suit.jpg]">
        <div class="max-w-100">
          <p class="mt5 p-5 text-lg font-bold underline underline-offset-4">
            <a href="/pl/collections/9013/spodnie-damskie">Spodnie damskie</a>
          </p>
        </div>
      </div>
    </div>
    <div
      class="min-h-120 flex items-end bg-cover bg-no-repeat bgi-[w-mf-category-4.png]"
    >
      <div class="max-w-100">
        <p class="mt5 p-5 text-lg font-bold underline underline-offset-4">
          <a href="/pl/collections/9014/kurtki-damskie">Kurtki damskie</a>
        </p>
      </div>
    </div>
  </div>
  <section aria-labelledby="collections-heading" class="mt10">
    <div class="mx-auto max-w-7xl px-4 lg:px-8 sm:px-6">
      <h2 class="text-2xl text-gray-900 font-bold tracking-tight">
        {{ $t('approval_page.other_products') }}
      </h2>
      <div id="productGrid" class="mx-auto max-w-2xl py-4 lg:max-w-none">
        <ProductGrid />
      </div>
    </div>
  </section>
  <div class="px-5 text-left md:grid md:grid-cols-2 md:mx-auto md:max-w-screen-xl md:px-0">
    <div class="items-end md:min-h-120 md:flex">
      <div class="max-w-100">
        <p class="text-2xl">
          Zobacz nasze koszulki damskie
        </p>
        <p class="text-lg">
          Nasze koszulki świetnie pasują, a nasze opcje kolorystyczne są naprawdę odpowiednie dla
          każdego
        </p>
        <p class="mt5 text-lg underline underline-offset-4">
          <a href="/pl/collections/9010/dla-kobiet">Spójrz na nasze ubrania</a>
        </p>
      </div>
      <div class="hidden md:block">
        <img src="/w-mf-video-min.png" alt="smallbanner">
      </div>
    </div>
    <div class="min-h-120 bg-right-top bg-no-repeat bgi-[w-mf-video-bg-min.png] md:bg-[length:75rem_25rem]" />
  </div>
  <div class="mx-auto max-w-screen-xl flex gap-4 p-5 md:px-0 md:py-5">
    <div
      class="hidden w-5/8 bg-cover bgi-[closeup-detail-fabric-texture-pajama-homewear-sleepwear-shopping-sale.jpg] md:block"
    />
    <div
      class="max-w-max w-8/8 flex flex-col items-center bg-primary-400 py-20 text-center md:w-3/8 md:p-10 md:py-30"
    >
      <p class="text-2xl">
        Dołącz do naszego<br> Newslatter Now
      </p>
      <p class="text-lg">
        Zapisz się do naszego newslettera i jako pierwszy otrzymuj informacje o nowościach ze świata
        Rivento.
      </p>
      <form action="#" method="post" class="mt-5">
        <input id="email" type="email" name="email" placeholder="Wprowadź swój e-mail" class="p-2">
        <button type="submit" onclick="alert('Dziękujemy za Twój e-mail, zostałeś zapisany do newslettera')" class="ml-4 bg-primary-5 p-2">
          Prześlij
        </button>
      </form>
    </div>
  </div>
</template>
